import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { InstantSearch, Configure } from 'react-instantsearch-dom';
// Hooks Querys & Context
import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line

import Layout from '@components/layout';
import { PageMeta } from '@components/pageMeta';
import { useIndustryQuery } from '../GraphQl/useIndustryQuery';
import { useLocationQuery } from '../GraphQl/useLocationQuery';
import { useServiceQuery } from '../GraphQl/useServiceQuery';
import { ProjectSearchBox } from '@components/search/projectSearchBox';
import { ProjectHits } from '@components/search/projectHits';
import { searchClient } from '@lib/algolia';

const ProjectsPage = ({ data: { page } }) => {
  const { seo, title, introText } = page || {};
  const { industries } = useIndustryQuery();
  const { locations } = useLocationQuery();
  const { services } = useServiceQuery();

  const [activeLocation, setActiveLocation] = useState(false);
  const [activeService, setActiveService] = useState(false);
  const [activeIndustry, setActiveIndustry] = useState(false);

  const selectStyles = `blockH7 input-field`;

  return (
    <Layout reverseHeader={true}>
      <PageMeta {...seo} />

      <div className=' pt-52 xl:pt-96 px-gutter text-blue pb-space-m'>
        <InstantSearch searchClient={searchClient} indexName='projects'>
          <Configure hitsPerPage={600} />
          <div className='lg:grid grid-cols-4'>
            <div className='col-start-1 col-end-4'>
              {' '}
              <h1 className='richH0 pb-5'>{title}</h1>
              <p className='richH2'>{introText}</p>
            </div>

            <div className='col-start-1 col-end-4  mt-12 flex flex-col md:grid  grid-cols-3 items-center justify-center  gap-x-12 gap-y-6'>
              <select
                className={selectStyles}
                onChange={(e) => {
                  e.target.value === 'default'
                    ? setActiveService(false)
                    : setActiveService(services?.[e.target.value]?.title);
                }}>
                <option value='default'>All Services</option>
                {services?.map((item, i) => (
                  <option key={i} value={i}>
                    {item?.title}
                  </option>
                ))}
              </select>
              <select
                className={selectStyles}
                onChange={(e) => {
                  e.target.value === 'default'
                    ? setActiveIndustry(false)
                    : setActiveIndustry(
                        industries[e.target.value]?.industryName,
                      );
                }}>
                <option value='default'>All Sectors</option>
                {industries?.map((item, i) => (
                  <option key={i} value={i}>
                    {item?.industryName}
                  </option>
                ))}
              </select>
              <select
                onChange={(e) => {
                  e.target.value === 'default'
                    ? setActiveLocation(false)
                    : setActiveLocation(
                        locations?.[e.target.value]?.locationName,
                      );
                }}
                className={selectStyles}>
                <option value='default'>All Locations</option>
                {locations?.map((item, i) => (
                  <option key={i} value={i}>
                    {item?.locationName}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-start-4 col-end-5 row-start-2 self-end pt-10 lg:pt-0   lg:pl-10 pl-0'>
              <ProjectSearchBox />
            </div>
          </div>
          <ProjectHits
            activeLocation={activeLocation}
            activeService={activeService}
            activeIndustry={activeIndustry}
          />
        </InstantSearch>
      </div>
    </Layout>
  );
};

export default ProjectsPage;

export const pageQuery = graphql`
  query projectsPageQuery {
    page: sanityProjectsHome {
      seo {
        ...SEO
      }
      title
      introText
    }
  }
`;
