import React from 'react';
import { Link } from 'gatsby';
import { connectHits } from 'react-instantsearch-dom';

const Hit = ({ slug, title, serviceRef, bannerImage }) => {
  return (
    <Link to={`${slug?.current}`} className='group  '>
      <div className='border-t link-overline overflow-hidden pt-5 '>
        <h1 className='blockH7 text-green mb-5'>{serviceRef?.title}</h1>
        <h1 className='blockH4 mb-10'>{title}</h1>
        <div className='aspect-w-5 aspect-h-4 relative overflow-hidden'>
          <img
            src={bannerImage?.asset?.url}
            className='w-full h-full absolute top-0 left-0 object-cover group-hover:scale-125 transition-all duration-700 ease-in-out'
            alt={bannerImage?.asset?.altText || ''}
          />
        </div>
      </div>
    </Link>
  );
};

const Hits = ({ hits, activeLocation, activeService, activeIndustry }) => {
  function compare(a, b) {
    if (a.orderRank < b.orderRank) {
      return -1;
    }
    if (a.orderRank > b.orderRank) {
      return 1;
    }
    return 0;
  }

  const sortedHits = hits.sort(compare);

  const filteredProjects = sortedHits?.filter((hit) => {
    if (activeLocation && activeService && activeIndustry) {
      return (
        hit?.locationRef?.locationName === activeLocation &&
        hit?.serviceRef?.title === activeService &&
        hit?.industryRef?.industryName === activeIndustry
      );
    } else if (activeLocation && activeService) {
      return (
        hit?.locationRef?.locationName === activeLocation &&
        hit?.serviceRef?.title === activeService
      );
    } else if (activeLocation && activeIndustry) {
      return (
        hit?.locationRef?.locationName === activeLocation &&
        hit?.industryRef?.industryName === activeIndustry
      );
    } else if (activeService && activeIndustry) {
      return (
        hit?.serviceRef?.title === activeService &&
        hit?.industryRef?.industryName === activeIndustry
      );
    } else if (activeLocation) {
      return hit?.locationRef?.locationName === activeLocation;
    } else if (activeService) {
      return hit?.serviceRef?.title === activeService;
    } else if (activeIndustry) {
      return hit?.industryRef?.industryName === activeIndustry;
    } else {
      return hit;
    }
  });

  return (
    <div className=' grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3   gap-x-8 gap-y-24 pt-space-m'>
      {filteredProjects.map((hit) => (
        <div className='  border-t-2  '>
          <Hit {...hit} />
        </div>
      ))}
      {filteredProjects.length === 0 && (
        <div className='col-start-2 col-end-3 text-center '>
          <h1 className='blockH4 mb-10 text-blue'>No results found</h1>
        </div>
      )}
    </div>
  );
};

export const ProjectHits = connectHits(Hits);
